﻿(function () {
    var autoplay = true;

    var el = document.querySelector('#js-slider-hero');

    if (el) {
        var autoplaySpeed = el.dataset.autoplaySpeed;
    }

    if (autoplaySpeed != null && autoplaySpeed > 0) {
        var sliderHero = new Swiper('.js-slider-hero', {
            lazy: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,

            },
            navigation: {
                nextEl: '.js-slider-hero__btn_next',
                prevEl: '.js-slider-hero__btn_previous',
            },
            autoplay: {
                delay: autoplaySpeed*1000,
                disableOnInteraction: true,
            },
            loop: true,
            a11y: true     
        });
    } else {
        var sliderHero = new Swiper('.js-slider-hero', {
            lazy: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true
            },
            navigation: {
                nextEl: '.js-slider-hero__btn_next',
                prevEl: '.js-slider-hero__btn_previous',
            }
        });
    }

    //Manage controls for second set of navigation buttons on mobile
    var btnNext2 = document.getElementsByClassName("js-slider-hero__btn_next_2")[0];
    var btnPrev2 = document.getElementsByClassName("js-slider-hero__btn_previous_2")[0];

    //On click, hijack onCLick method of primary sliderjs navigation elements
    if (btnNext2) {
        btnNext2.onclick = function() {
            sliderHero.navigation.nextEl.click();
        };
    }
    if (btnPrev2) {
        btnPrev2.onclick = function() {
            sliderHero.navigation.prevEl.click();
        };
    }

    sliderHero.on('slideChange', function () {
        removeActive();
        updateOutsideContent(sliderHero.realIndex);


        //On slide change, update secondary controls nav buttons
        if (sliderHero.isEnd) {
            btnNext2.classList.add("swiper-button-disabled");
        } else {
            btnNext2.classList.remove("swiper-button-disabled");
        }

        if (sliderHero.activeIndex == 0) {
            btnPrev2.classList.add("swiper-button-disabled");
        } else {
            btnPrev2.classList.remove("swiper-button-disabled");
        }
    });
})();

//Remove hover state on mobile devices,  
function removeHoverEffect() {
    if ('ontouchstart' in document.documentElement) {
        for (var sheetI = document.styleSheets.length - 1; sheetI >= 0; sheetI--) {
            var sheet = document.styleSheets[sheetI];
            if (sheet.cssRules) {
                for (var ruleI = sheet.cssRules.length - 1; ruleI >= 0; ruleI--) {
                    var rule = sheet.cssRules[ruleI];

                    if (rule.selectorText) {
                        rule.selectorText = rule.selectorText.replace(':hover', ':active');
                    }
                }
            }
        }
    }
}

function removeActive() {
    var outsideContent2 = Array.from(document.querySelectorAll('.js-slider-content'))
    outsideContent2.forEach((arrayElement, index) => {
        arrayElement.classList.remove("active");
    });
}

var currentIndex = 1;
function updateOutsideContent(slideIndex) {
    var outsideContent = Array.from(document.querySelectorAll('.js-slider-content'))
    outsideContent.forEach((arrayElement, index) => {

        if (slideIndex == index) {
            removeActive();
            outsideContent[index].classList.add("active");
            //On slide change prevent pagination button staying as active
            removeHoverEffect();
        }

    });
};


